const IS_DEV = process.env.NODE_ENV === "development";

export const config_production = {
  feathers: {
    host: "api.jgs.goqa.co.za",
    port: -1,
    secure: true
  }
};

export const config_dev = {
  feathers: {
    host: "localhost",
    port: 3030,
    secure: false
  }
};

export const feathersConnectionString = !IS_DEV
  ? `http${config_production.feathers.secure ? "s" : ""}://${
      config_production.feathers.host
    }${
      config_production.feathers.port !== -1
        ? ":" + config_production.feathers.port
        : ""
    }`
  : `http${config_dev.feathers.secure ? "s" : ""}://${
      config_dev.feathers.host
    }${config_dev.feathers.port !== -1 ? ":" + config_dev.feathers.port : ""}`;
